import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/Blog/graphql-error-list";
import PortableText from "../components/Blog/portableText";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import AccelerateYourBusiness from "../components/Repeating/AccelerateYourBusiness";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const StyledContent = styled.div`
  ul,
  ol {
    ${tw`mb-8 space-y-2.5`}
    li {
      ${tw`flex items-baseline`}
      &:before {
        content: "\\f00c";
        font-family: "Font Awesome 5 Pro";
        ${tw`mr-3 text-xs font-bold text-white bg-primary-700 min-w-[18px] h-[18px] flex items-center justify-center rounded-full`}
      }
    }
  }
`;

export const query = graphql`
  query AppsTemplateQuery($id: String!) {
    page: sanityApps(id: { eq: $id }) {
      id
      seoTitle
      metaDescription
      openGraphImage {
        asset {
          url
        }
      }
      twitterCardImage {
        asset {
          url
        }
      }
      title
      heroImage {
        asset {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      heroHeading
      _rawHeroText(resolveReferences: { maxDepth: 5 })
      _rawWhyUsText(resolveReferences: { maxDepth: 5 })
      realSynchSetupImage {
        asset {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      zapierSetupImage {
        asset {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      integrationHeading
      integrationHeadingSubtext
      integrationSubHeading
      _rawIntegrationText(resolveReferences: { maxDepth: 5 })
      integrationImage {
        asset {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`;

const AppsTemplate = (props) => {
  const { data, errors } = props;
  const page = data && data.page;
  return (
    <Layout headerStyle="overlap">
      {errors && <SearchEngineOptimization title="GraphQL Error" />}
      {page && (
        <SearchEngineOptimization
          title={page.seoTitle}
          description={page.metaDescription}
          openGraphImage={page.openGraphImage && page.openGraphImage.asset.url}
          twitterOpenGraphImage={
            page.twitterCardImage && page.twitterCardImage.asset.url
          }
        />
      )}

      {errors && (
        <div className="container ">
          <GraphQLErrorList errors={errors} />
        </div>
      )}

      {page && (
        <>
          <section className="relative z-10 rounded-bl-[60px] bg-primary-900 pt-20 pb-20 md:rounded-bl-[120px] md:pt-32 md:pb-28 lg:rounded-bl-[220px]">
            <div className="container pt-20">
              <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-14 lg:gap-x-24">
                <div className="order-2 md:order-1">
                  <h1 className="text-white">{page.heroHeading}</h1>
                  {page._rawHeroText && (
                    <div className="text-white/80">
                      <PortableText blocks={page._rawHeroText} />
                    </div>
                  )}
                  <ButtonSolid
                    modal="modal-contact"
                    text="Schedule a Consult"
                  />
                </div>
                <div className="order-1 md:order-2">
                  {page.heroImage && page.heroImage.asset && (
                    <GatsbyImage
                      image={page.heroImage.asset.gatsbyImageData}
                      alt={page.heroHeading}
                      width={page.heroImage.width}
                      loading="eager"
                    />
                  )}
                </div>
              </div>
            </div>
          </section>

         {/*} <section className="relative mb-20 bg-primary-200 py-20 md:mb-32 md:py-32">
            <div className="absolute top-0 h-full w-full -translate-y-full transform bg-primary-200"></div>
            <div className="container">
              <header className="mx-auto mb-12 max-w-[680px] text-center md:mb-14">
                <h2>Why Choose Real Synch Over Zapier?</h2>
                {page._rawWhyUsText && (
                  <PortableText blocks={page._rawWhyUsText} />
                )}
                <p></p>
              </header>

              <div className="relative mx-auto grid max-w-[1120px] items-center gap-y-6 md:grid-cols-2 md:gap-x-32 lg:gap-x-44">
                <div className="z-10 h-full rounded-[30px] bg-white px-6 pb-5 pt-8 text-center shadow-4xl md:px-10 md:pb-6 lg:px-14">
                  <p className="mb-5 text-lg font-bold text-typography-heading">
                    Setting up Real Synch for {page.title}
                  </p>
                  {page.realSynchSetupImage && (
                    <div className="mb-4 md:mb-5">
                      <GatsbyImage
                        image={page.realSynchSetupImage.asset.gatsbyImageData}
                        alt={`Setting up Real Synch for ${page.title}`}
                        width={page.integrationImage.width}
                        loading="lazy"
                      />
                    </div>
                  )}
                  <div className="mb-0.5 text-4xl font-extrabold text-typography-heading md:mb-2">
                    4 Clicks
                  </div>
                  <div className="text-6xl">😎</div>
                </div>

                <div className="inset-0 flex items-center justify-center md:absolute">
                  <div className="relative flex w-full items-center justify-center">
                    <div className="absolute inset-0 m-auto h-1 w-[219px] rotate-90 transform bg-typography-heading/20 md:rotate-0"></div>
                    <div className="z-10 flex h-12 w-12 items-center justify-center rounded-full border border-typography-heading bg-[#BBC6D7] text-lg font-bold text-typography-heading md:h-16 md:w-16 md:text-3xl">
                      VS
                    </div>
                  </div>
                </div>

                <div className="z-10 h-full rounded-[30px] bg-white px-6 pb-5 pt-8 text-center shadow-4xl md:px-10 md:pb-6 lg:px-14">
                  <p className="mb-5 text-lg font-bold text-typography-heading">
                    Setting up Zapier for {page.title}
                  </p>
                  {page.zapierSetupImage && (
                    <div className="mb-4 md:mb-5">
                      <GatsbyImage
                        image={page.zapierSetupImage.asset.gatsbyImageData}
                        alt={`Setting up Real Synch for ${page.title}`}
                        width={page.integrationImage.width}
                        loading="lazy"
                      />
                    </div>
                  )}
                  <div className="mb-0.5 text-4xl font-extrabold text-typography-heading md:mb-2">
                    20+ Clicks
                  </div>
                  <div className="text-6xl">😟</div>
                </div>
              </div>
            </div>
          </section> {*/}

         <section className="my-20 md:mb-32">
            <div className="container">
            <h2 className="text-center">{page.integrationHeading}</h2>
                  <p className="text-center">{page.integrationHeadingSubtext}</p>
              <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-14 lg:gap-x-24 p-12">
                <div className="order-2 md:order-1">
                  {page.integrationImage && page.integrationImage.asset && (
                    <GatsbyImage
                      image={page.integrationImage.asset.gatsbyImageData}
                      alt={`${page.title} logo`}
                      width={page.integrationImage.width}
                      loading="lazy"
                    />
                  )}
                </div>
                <div className="order-1 md:order-2">
                  <h3>{page.integrationSubHeading}</h3>
                  {page._rawHeroText && (
                    <StyledContent>
                      <PortableText blocks={page._rawIntegrationText} />
                    </StyledContent>
                  )}
                  <ButtonSolid
                    modal="modal-contact"
                    text="Schedule a Consult"
                  />
                </div>
              </div>
            </div>
          </section>

          <Testimonials />
          <AccelerateYourBusiness />
          <CallToAction />
        </>
      )}
    </Layout>
  );
};

export default AppsTemplate;
